import React from 'react';
import Layout from './src/components/Layout';
import CookieBanner from './src/components/CookieBanner';

export const wrapRootElement = ({ element }) => (
  <>
    <CookieBanner />
    {element}
  </>
);

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}
